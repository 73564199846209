import { createSlice } from '@reduxjs/toolkit';

const administratorsSlice = createSlice({
  name: 'Administrators',
  initialState: {
    allAdministrators: []
  },

  reducers: {
    setAllAdministrators: (state, action) => {
      state.allAdministrators = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAllAdministrators } = administratorsSlice.actions;

export default administratorsSlice.reducer;
