import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/admin/dashboard/dashboard')));
const AllAdministrators = Loadable(lazy(() => import('views/admin/administrators/all-administrators')));
const AddAdministrators = Loadable(lazy(() => import('views/admin/administrators/add-administrators')));
const SingleAdministrators = Loadable(lazy(() => import('views/admin/administrators/single-administrators')));

const AllBusinesses = Loadable(lazy(() => import('views/admin/businesses/all-businesses')));
const AddBusiness = Loadable(lazy(() => import('views/admin/businesses/add-businesses')));
const SingleBusiness = Loadable(lazy(() => import('views/admin/businesses/single-businesses')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  // I'm not very sure about this
  // TODO: TEST it
  path: '/admin',
  element: <MainLayout />,
  children: [
    // sends user to 404 page
    // TODO: show 404 nd redirect to dashboard
    // {
    //   path: '*',
    //   element: <Dashboard />
    // },

    // ============== dashboard =====================

    {
      path: '/admin',
      element: <Navigate to="/admin/dashboard" />
    },

    {
      path: '/admin/',
      element: <Navigate to="/dashboard" />
    },

    {
      path: '/admin/dashboard',
      element: <Dashboard />
    },

    // ============== dashboard =====================

    // administrators ====================================
    {
      path: '/admin/administrators',
      children: [
        {
          path: 'add',
          element: <AddAdministrators />
        },
        {
          path: ':id/edit',
          element: <SingleAdministrators />
        },

        // use this to view administrator
        {
          path: ':id',
          element: <SingleAdministrators />
        },

        {
          path: '',
          element: <AllAdministrators />
        }
      ]
    },
    // ==================== administrators ====================================

    // ========================== businesses ============================
    {
      path: '/admin/businesses',
      children: [
        {
          path: '',
          element: <AllBusinesses />
        },
        // use this to view business
        // {
        //   path: ':id',
        //   element: <SingleBusiness />
        // },
        {
          path: ':id/edit',
          element: <SingleBusiness />
        },
        {
          path: 'add',
          element: <AddBusiness />
        }
      ]
    }
    // ========================== businesses ============================
  ]
};

export default MainRoutes;
