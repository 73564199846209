import { createSlice } from '@reduxjs/toolkit';

const userProfileSlice = createSlice({
  name: 'user profile',
  initialState: {
    userProfile: null
  },

  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setUserProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
