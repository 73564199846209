import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option
const Homepage = Loadable(lazy(() => import('views/website/home/homepage')));
const AboutUs = Loadable(lazy(() => import('views/website/about/about-us')));
const BusinessCategory = Loadable(lazy(() => import('views/website/business/category-businesses')));
const AllBusinessCategories = Loadable(lazy(() => import('views/website/business/allBusinessCategories')));
const BusinessDetails = Loadable(lazy(() => import('views/website/business/business-details')));
const SearchResult = Loadable(lazy(() => import('views/website/search/search-result')));
const ContactUs = Loadable(lazy(() => import('views/website/contact/contact-us')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const WebsiteRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '',
      element: <Homepage /> // change this to homepage
    },

    {
      path: '/about-us',
      element: <AboutUs />
    },

    // show all categories
    {
      path: '/business/categories',
      element: <AllBusinessCategories />
    },

    {
      path: '/business/categories/:category',
      element: <BusinessCategory />
    },

    {
      path: '/business/:businessID',
      element: <BusinessDetails />
    },

    {
      path: '/search-results',
      element: <SearchResult />
    },

    {
      path: '/contact',
      element: <ContactUs />
    }
  ]
};

export default WebsiteRoutes;
