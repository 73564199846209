import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { menuOpen } from '../../../store/features/customizationSlice';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();

  return (
    <ButtonBase disableRipple onClick={() => dispatch(menuOpen(defaultId))} component={Link} to={config.defaultPath}>
      <div className="flex justify-center items-center w-full">
        <Logo />
        {/* <h1 className="self-center font-bold text-base">UCB</h1> */}
      </div>
    </ButtonBase>
  );
};

export default LogoSection;
