import { createSlice } from '@reduxjs/toolkit';

const organisationSlice = createSlice({
  name: 'organisation profile',
  initialState: {
    organisationProfile: {}
  },

  reducers: {
    setOrganisationProfile: (state, action) => {
      state.organisationProfile = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setOrganisationProfile } = organisationSlice.actions;

export default organisationSlice.reducer;
