import administrators from './administrators';
import businesses from './businesses';
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, businesses, administrators]
};

export default menuItems;
