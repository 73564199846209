import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './admin/MainRoutes';
import AuthenticationRoutes from './admin/AuthenticationRoutes';
import WebsiteRoutes from './website/WebsiteRoutes';



// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    AuthenticationRoutes,
     WebsiteRoutes
  ]);
}
