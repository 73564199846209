import { createSlice } from '@reduxjs/toolkit';

const businessesSlice = createSlice({
  name: 'Employees',
  initialState: {
    isSearch: false,
    allBusinesses: [],
    selectedBusiness: null,
    categoryBusinesses: [],
    searchResults: [],
    allBusinessAreas: []
  },

  reducers: {
    setAllBusinesses: (state, action) => {
      state.allBusinesses = action.payload;
    },
    setSelectedBusiness: (state, action) => {
      state.selectedBusiness = action.payload;
    },
    setCategoryBusinesses: (state, action) => {
      state.categoryBusinesses = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setBusinessAreas: (state, action) => {
      state.allBusinessAreas = action.payload;
    },
    setIsSearch: (state, action) => {
      state.isSearch = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAllBusinesses, setSelectedBusiness, setCategoryBusinesses, setBusinessAreas, setSearchResults, setIsSearch } =
  businessesSlice.actions;

export default businessesSlice.reducer;
