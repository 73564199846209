import { createSlice } from '@reduxjs/toolkit';

const categoriesSlice = createSlice({
  name: 'Categories',
  initialState: {
    allCategories: [],
    drawerOpen: false,
    selectedCategory: {},
    topCategories: [],
  },

  reducers: {
    setAllCategories: (state, action) => {
      state.allCategories = action.payload;
    },
    setTopCategories: (state, action) => {
      state.topCategories = action.payload;
    },
    toggleDrawer: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAllCategories, toggleDrawer, setSelectedCategory, setTopCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
