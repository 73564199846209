import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option
const Login = Loadable(lazy(() => import('views/admin/auth/signin/signin')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/admin',
  element: <MinimalLayout />,
  children: [

    {
      path: '/admin/login',
      element: <Login />
    }
    
  ]
};

export default AuthenticationRoutes;
