// assets
import { IconBuildingSkyscraper } from '@tabler/icons';

// constant
const icons = { IconBuildingSkyscraper };

// ==============================|| businesses MENU ITEMS ||============================== //

const businesses = {
  id: 'businesses',
  title: 'Businesses',
  type: 'group',
  children: [
    {
      id: 'businesses',
      title: 'Businesses',
      type: 'item',
      url: '/admin/businesses',
      icon: icons.IconBuildingSkyscraper,
      breadcrumbs: false
    }
  ]
};

export default businesses;
