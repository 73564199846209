import { combineReducers } from '@reduxjs/toolkit';
import customizationSlice from './features/customizationSlice';
import userProfileSlice from './features/userProfileSlice';
import administratorsSlice from './features/administratorsSlice';
import businessesSlice from './features/businessesSlice';
import categoriesSlice from './features/categoriesSlice';
import organisationSlice from './features/organisationSlice';

// const persistConfig = {
//   key: "root",
// };

const rootReducer = combineReducers({
  administrators: administratorsSlice,
  customization: customizationSlice,
  userProfile: userProfileSlice,
  businesses: businessesSlice,
  categories: categoriesSlice,
  organisation: organisationSlice,
});

export default rootReducer;
