import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer"


// const persistConfig = {
//   key: "root",
// };


export const store = configureStore({
  reducer: rootReducer,
});
